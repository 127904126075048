
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["image_field", "video_field", "image_input_field", "video_input_field", "content_type_input_field"]

  connect() {
    console.log("content_type_input_field", this.content_type_input_fieldTarget.value)
    if (this.content_type_input_fieldTarget.value == "image") {
      this.video_fieldTarget.classList.add('d-none')
      this.image_fieldTarget.classList.remove('d-none')
    } else if (this.content_type_input_fieldTarget.value == "video") {
      this.image_fieldTarget.classList.add('d-none')
      this.video_fieldTarget.classList.remove('d-none')
    }
  }
  content_type_change(event) {
    event.preventDefault();
    console.log(event.target.value)

    if (event.target.value == "image") {
      this.video_fieldTarget.classList.add('d-none')
      this.image_fieldTarget.classList.remove('d-none')
    } else if (event.target.value == "video") {
      this.image_fieldTarget.classList.add('d-none')
      this.video_fieldTarget.classList.remove('d-none')
    }

  }

}
