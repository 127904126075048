import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["videourlfield"]

  // connect() {
  //   this.show_field()
  // }

  toggle_video_url(event) {
    event.preventDefault();
    this.videourlfieldTarget.classList.toggle('d-none')
  }

}
